@charset "ISO-8859-1";

html {
  scroll-behavior: smooth !important;
}
a {
  text-decoration: none !important;
  cursor: pointer !important;
}

.sample-box-shadow {
  box-shadow: 1px 1px 87px -46px rgba(15, 143, 172, 0.79) !important;
  -webkit-box-shadow: 1px 1px 87px -46px rgba(19, 100, 128, 0.79) !important;
  -moz-box-shadow: 1px 1px 87px -46px rgba(9, 31, 104, 0.79) !important;
}

.site-text {
  font-family: "Montserrat Alternates", sans-serif !important;
}
.landing-text {
  font-family: "Fredericka the Great", cursive !important;
}

.sub-text {
  font-family: "Black Ops One", cursive;
}

.other-text {
  font-family: "Noto Sans", "Montserrat", sans-serif !important;
}

.sample-text {
  font-family: "Noto Sans", "Montserrat", sans-serif !important;
}

.gradient-one {
  background: rgb(0, 36, 30);
  background: linear-gradient(
    90deg,
    rgba(0, 36, 30, 1) 0%,
    rgba(9, 86, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

.gradient-two {
  background: rgb(255, 214, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 214, 0, 1) 0%,
    rgba(255, 145, 0, 0.9444152661064426) 0%,
    rgba(255, 214, 0, 1) 100%
  );
}

.gradient-three {
  background: rgb(0, 36, 30);
  background: linear-gradient(
    90deg,
    rgba(0, 36, 30, 1) 0%,
    rgba(9, 86, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

.gradient-four {
  background: rgb(0, 36, 30);
  background: linear-gradient(
    90deg,
    rgba(0, 36, 30, 1) 0%,
    rgba(9, 86, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

.app {
  background-color: #040d14 !important;
}

.gradient-one {
  background: rgb(0, 36, 30);
  background: linear-gradient(
    90deg,
    rgba(0, 36, 30, 1) 0%,
    rgba(9, 86, 121, 1) 0%,
    rgb(12, 67, 78) 100%
  );
}

.gradient-two {
  background: rgb(255, 214, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 214, 0, 1) 0%,
    rgba(255, 145, 0, 0.9444152661064426) 0%,
    rgba(255, 214, 0, 1) 100%
  );
}

.gradient-three {
  background: rgb(0, 36, 30);
  background: linear-gradient(
    90deg,
    rgba(0, 36, 30, 1) 0%,
    rgba(9, 86, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

.set-background-image {
  background-image: url(../images/bg.jpg) !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.custom-box-shadow {
  box-shadow: 1px 1px 87px -46px rgba(115, 115, 115, 0.79) !important;
  -webkit-box-shadow: 1px 1px 87px -46px rgba(103, 100, 100, 0.79) !important;
  -moz-box-shadow: 1px 1px 87px -46px rgba(113, 109, 109, 0.79) !important;
}

.card-border-top {
  border-top: 7px solid;
}
